p {
}

.mydt {
}

.mydt table {
}

.mydt th {
}

.DayPicker-Day {
  border-radius: unset;
}

.btn-width {
  width: unset !important;
}

.form-group {
  margin-bottom: 1rem;
}

.action-wrapper {
  text-align: center;
}

.save-comment-btn {
  width: 50% !important;
}

.spinner-wrapper {
  text-align: center;
  margin-bottom: 25px;
}

.spinner-wrapper-home {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.outdoor-field {
  padding-left: 15px;
}

/* .row>* {
  width: unset !important;
} */
