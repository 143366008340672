.bg-linesImg {
    background-image: url("../../img//disclosure/lines.svg");
}

.textStyleHead{
    color: #263a64;
    font-size: 28px;
    font-family: --e-global-typography-primary-font-family;
}
.textStyle{
    color: #1a1815;
    font-family: var(--e-global-typography-text-font-family),Sans-serif;
}
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
.imgSize {
    width: 90%;
    object-fit: contain;
    aspect-ratio: 1/1;
}
  